








import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import __ from '@/shared/helpers/__'
import FormBase from '@/shared/classes/form/form-base'
import Client from '@/shared/modules/client/client.model'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import { LOCALES } from '@/shared/constants/locales.constants'
import SelectItem from '@/shared/classes/form/fields/Select/select-item'
import { CompanyRoutes } from '@/shared/router/company'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import { RoleLevel } from '@/shared/modules/role/role.model'

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class ClientsCreate extends Vue {
  form: FormBase = new FormBase()
    .setEndpoint(`company/${ this.company.slug }/clients`)
    .setFiles(true)
    .setModel(Client)
    .setFields([
      new Field()
        .setType(FieldTypes.file)
        .setKey('avatar')
        .setTitle(__('company.views.clients.form.avatar'))
        .setMeta({ type: 'image', accept: 'image/*' }),
      new Field()
        .setKey('first_name')
        .setEntryKey('firstName')
        .setTitle(__('company.views.clients.form.client-name'))
        .setSize(FieldSizes.half)
        .isRequired(),
      // new Field()
      //   .setEntryKey('lastName')
      //   .setKey('last_name')
      //   .setTitle(__('company.views.clients.form.last_name'))
      //   .setSize(FieldSizes.half),
      // new Field()
      //   .setType(FieldTypes.email)
      //   .setKey('email')
      //   .setTitle(__('company.views.clients.form.email'))
      //   .setSize(FieldSizes.half)
      //   .isRequired(),
      new Field()
        .setKey('phone')
        .setTitle(__('company.views.clients.form.phone'))
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.datePicker)
        .setEntryKey('birthDate')
        .setKey('birth_date')
        .setTitle(__('company.views.clients.form.birth_date'))
        .setSize(FieldSizes.half),
      new Field()
        .setKey('address')
        .setTitle(__('company.views.clients.form.address')),
      new SelectField()
        .setKey('role_uuid')
        .setEntryKey('roleUuid')
        .setTitle(__('company.views.clients.form.role'))
        .loadItems({
          endpoint: '/roles',
          value: 'uuid',
          title: 'name',
          filters: [
            {
              type: FilterOperators.equals,
              name: 'level',
              value: RoleLevel.client,
            }
          ],
        })
        .setSize(FieldSizes.half),
      new SelectField()
        .setEntryKey('defaultLocale')
        .setKey('default_locale')
        .setTitle(__('company.views.clients.form.default_locale'))
        .setItems(Object.keys(LOCALES).map((key: string): SelectItem => new SelectItem()
          .setTitle(LOCALES[key].title)
          .setValue(key)
        ))
        .setSize(FieldSizes.half),
      new Field()
        .setKey('company_code')
        .setTitle(__('company.views.clients.form.company-code'))
        .setSize(FieldSizes.half),
      new Field()
        .setKey('contact_person')
        .setTitle(__('company.views.clients.form.contact-person'))
        .setSize(FieldSizes.half),
    ])
    .setSubmit({
      text: __('company.views.clients.create.form.submit')
    })
    .setOnSuccess(this.onSuccess)

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.clientsIndex })
  }

  private onSuccess(): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('company.views.clients.create.form.on-success'),
    })

    this.goBack()
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
